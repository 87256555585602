import React from "react";
import styled from "styled-components";
import logo from "../../../assets/logo/Logo.svg";

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 56.78px;
`;
const LogoIcon = styled.img`
  width: 153.865px;
  height: 34.905px;
  flex-shrink: 0;
`;
const index = () => {
  return (
    <Header>
      <LogoIcon src={logo} alt="" />
    </Header>
  );
};

export default index;
