import React from "react";
import styled from "styled-components";
import UpperHeroSection from "./UpperHeroSection";
import gridLines from "../../assets/frame/gridLines.svg";
import gridLinesMobile from "../../assets/frame/gridlines-mobile.svg";
import EarlyAccessButton from "../Buttons/EarlyAccessButton";
import iphoneFrame from "../../assets/Illustrations/svgs/iphone-frame.svg";
import iphoneMobileFrame from "../../assets/Illustrations/svgs/iphone-mobile-frame.svg";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 74px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 86px;
  }
  /* border: 2px solid green; */
`;
const GridLines = styled.div`
  position: absolute;
`;
const GridLinesImage = styled.img`
  display: flex;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;
const GridLinesMobileImage = styled.img`
  display: none;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
  }
`;
const EarlyAccessButtonDiv = styled.div`
  margin-top: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 11;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 64px;
  }
`;
const IphoneFrameDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 11;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 59.11px;
    position: relative;
  }
`;
const IphoneFrameImage = styled.img`
  display: flex;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;
const IphoneMobileFrameImage = styled.img`
  display: none;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
  }
`;
const RectangleOverlay = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
    width: 395px;
    height: 174px;
    flex-shrink: 0;
    background: linear-gradient(
      180deg,
      #fff 25.22%,
      rgba(255, 255, 255, 0) 95.12%
    );
    position: absolute;
    bottom: 0;
    transform: rotate(180deg);
  }
`;
const Index = () => {
  return (
    <MainDiv>
      <GridLines>
        <GridLinesImage src={gridLines} alt="" />
        <GridLinesMobileImage src={gridLinesMobile} alt="" />
      </GridLines>
      <UpperHeroSection />
      <EarlyAccessButtonDiv>
        <EarlyAccessButton />
      </EarlyAccessButtonDiv>
      <IphoneFrameDiv>
        <IphoneFrameImage src={iphoneFrame} alt="" />
        <IphoneMobileFrameImage src={iphoneMobileFrame} alt="" />
        <RectangleOverlay />
      </IphoneFrameDiv>
    </MainDiv>
  );
};

export default Index;
