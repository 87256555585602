import React from "react";
import "./App.css";
import "./theme/clash-grotesk.css";
import HomeScreen from "./pages/HomeScreen";

function App() {
  return (
    <>
      <HomeScreen />
    </>
  );
}

export default App;
