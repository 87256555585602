import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 11;
`;
const HeadingBox = styled.div`
  width: 829px;
  text-align: center;
  padding: 0 40px 0 40px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;
const Text = styled.p`
  color: #666;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  font-family: "ClashGrotesk-Regular";
  @media (max-width: ${BREAKPOINTS.xs}px) {
    text-align: center;
    font-size: 16px;
    line-height: 22px; /* 137.5% */
    width: 85%;
  }
`;
const Heading2 = styled.h2`
  color: #200c32;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 50.958px; /* 106.162% */
  letter-spacing: -1.44px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    text-align: center;
    font-size: 32px;
    line-height: 38px; /* 118.75% */
    letter-spacing: -0.96px;
  }
`;
const TitleBox = () => {
  return (
    <MainDiv>
      <HeadingBox>
        <Heading2>Elevate your shopping experience with Zooox</Heading2>
      </HeadingBox>
      <Text>
        You can manage all e-commerce mails. Organize your newsletters, e-bills
        and earn quick cashback rewards with one download.
      </Text>
    </MainDiv>
  );
};

export default TitleBox;
