import React from "react";
import styled from "styled-components";
import liveNowButton from "../../assets/frame/live-now-btn.svg";
import upcomingButton from "../../assets/frame/upcoming-btn.svg";
import iphoneFrame from "../../assets/frame/Iphone15-frame.svg";
import card1Frame from "../../assets/frame/card1-frame.svg";
import card2Frame from "../../assets/frame/card2-frame.svg";
import card3Frame from "../../assets/frame/card3-frame.svg";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  margin-top: 88px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 55px;
  }
`;
const Row1 = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding: 0 16px;
  }
`;
const Row2 = styled.div`
  display: flex;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    padding: 0 16px;
  }
`;
const Card1 = styled.div`
  display: flex;
  width: 456px;
  height: 451px;
  padding: 28px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #fff;
  position: relative;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
    height: 400px;
    overflow: hidden;
  }
`;
const Card1Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19.5px;
  flex: 1 0 0;
`;
const Card2 = styled.div`
  display: flex;
  width: 456px;
  height: 357px;
  padding: 28px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #ededed;
  background: var(--Neutral-0, #fff);
  position: relative;
  overflow: hidden;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;

    height: 345px;
  }
`;
const Card2Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19.6px;
  flex: 1 0 0;
`;
const Card3 = styled.div`
  display: flex;
  width: 932px;
  height: 362px;
  padding: 28px;
  /* justify-content: center; */
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ededed;
  background: var(--Neutral-0, #fff);
  position: relative;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
    height: 330px;
  }
`;
const Card3Content1 = styled.div`
  display: flex;
  width: 446px;
  flex-direction: column;
  align-items: flex-start;
  gap: 19.6px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;
const Card3Content2 = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const Frame1 = styled.img`
  position: absolute;
  bottom: 0;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 95%;
    height: 221px;
  }
`;
const Frame2 = styled.img`
  position: absolute;
  bottom: 0px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 85%;
    height: 169px;
  }
`;
const Frame3 = styled.img`
  position: absolute;
  bottom: 0px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 85%;
    height: 153px;
  }
`;
const Heading5 = styled.h5`
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 114.286% */
  @media (max-width: ${BREAKPOINTS.xs}px) {
    leading-trim: both;
    text-edge: cap;
    font-size: 20px;
    line-height: 22px;
  }
`;
const Paragraph1 = styled.p`
  color: #4d4d4d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
  font-family: "ClashGrotesk-Regular";
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 14px;
    line-height: 19px; /* 135.714% */
  }
`;
const IphoneFrame = styled.img``;
const CardLayoutContainer = () => {
  return (
    <MainDiv>
      <Row1>
        <Card1>
          <Card1Content>
            <img src={liveNowButton} alt="" />
            <TextBox>
              <Heading5>Unified Shopping Mailbox</Heading5>
              <Paragraph1>
                Your shopping emails, brought together in one refined, perfectly
                organized inbox.
              </Paragraph1>
            </TextBox>
            <Frame1 src={card1Frame} alt="" />
          </Card1Content>
        </Card1>
        <Card2>
          <Card2Content>
            <img src={upcomingButton} alt="" />
            <TextBox>
              <Heading5>
                Simplify your finances with AI-powered insights.
              </Heading5>
              <Paragraph1>
                Effortlessly track your expenses and uncover valuable insights
              </Paragraph1>
            </TextBox>
            <Frame2 src={card2Frame} alt="" />
          </Card2Content>
        </Card2>
      </Row1>
      <Row2>
        <Card3>
          <Card3Content1>
            <img src={upcomingButton} alt="" />
            <TextBox>
              <Heading5>Shop & Earn Real Cashback</Heading5>
              <Paragraph1>
                Shop with Zooox, earn cashback instantly. Enjoy real savings!
              </Paragraph1>
            </TextBox>
            <Frame3 src={card3Frame} alt="" />
          </Card3Content1>
          <Card3Content2>
            <IphoneFrame src={iphoneFrame} alt="" />
          </Card3Content2>
        </Card3>
      </Row2>
    </MainDiv>
  );
};

export default CardLayoutContainer;
