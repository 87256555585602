import React from "react";
import styled from "styled-components";
import letsGetStartedButton from "../../assets/frame/lets-get-started-btn.svg";
import TitleBox from "./TitleBox";
import CardLayoutContainer from "./CardLayoutContainer";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 25px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 70px;
  }
`;
const Image = styled.img`
  @media (max-width: ${BREAKPOINTS.xs}px) {
  }
`;
const Index = () => {
  return (
    <MainDiv>
      <Image src={letsGetStartedButton} alt="" />
      <TitleBox />
      <CardLayoutContainer />
    </MainDiv>
  );
};

export default Index;
