import React from "react";
import styled from "styled-components";
import TitleBox from "./TitleBox";
import EarlyAccessButton from "../Buttons/EarlyAccessButton";
import halfCircleFrame from "../../assets/frame/half-circle-frame.svg";
import yellowIphoneFrame from "../../assets/frame/yellow-iphone-frame.svg";
import { BREAKPOINTS } from "../../theme/breakpoints";
import gridLinesMobile from "../../assets/frame/gridlines-mobile.svg";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 120px;
  position: relative;
  height: 500px;
  position: relative;
  height: 738px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 65px;
    height: 690px;
  }
`;
const ButtonDiv = styled.div`
  margin-top: 106.28px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 72px;
    z-index: 11;
  }
`;
const HalfCircleImage = styled.img`
  position: absolute;
  bottom: 0;
`;
const YellowIphoneImage = styled.img`
  position: absolute;
  bottom: 0;
  border-right: 6px solid #eebd6c;
  border-left: 6px solid #eebd6c;
  border-radius: 40px 40px 0 0;
  background-color: #eebd6c;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 287px;
  }
`;
const GridLines = styled.div`
  position: absolute;
  display: none;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
    top: 120px;
  }
`;
const GridLinesMobileImage = styled.img`
  display: none;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
  }
`;
const Index = () => {
  return (
    <MainDiv>
      {" "}
      <GridLines>
        <GridLinesMobileImage src={gridLinesMobile} alt="" />
      </GridLines>
      <TitleBox />
      <ButtonDiv>
        <EarlyAccessButton />
      </ButtonDiv>
      <HalfCircleImage src={halfCircleFrame} alt="" />
      <YellowIphoneImage src={yellowIphoneFrame} alt="" />
    </MainDiv>
  );
};

export default Index;
