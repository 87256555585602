import React from "react";
import styled from "styled-components";
import TitleBox from "./TitleBox";
import bigIcon from "../../assets/Illustrations/svgs/big-controlfinance-icon.svg";
import gridLines from "../../assets/frame/gridLines.svg";
import { BREAKPOINTS } from "../../theme/breakpoints";
import gridLinesMobile from "../../assets/frame/gridlines-mobile.svg";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 120px;
  gap: 103px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 60px;
    gap: 36px;
  }
`;
const GridLines = styled.div`
  position: absolute;
  bottom: 40px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    bottom: 80px;
  }
`;
const GradientRectangle = styled.div`
  width: 100%;
  height: 293px;
  flex-shrink: 0;
  opacity: 0.1;
  background: linear-gradient(
    0deg,
    #2b0341 -36.81%,
    rgba(205, 148, 255, 0) 100%
  );
  position: absolute;
  bottom: 0;
`;
const Image = styled.img`
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 85%;
  }
`;
const GridLinesImage = styled.img`
  display: flex;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;
const GridLinesMobileImage = styled.img`
  display: none;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
  }
`;
const Index = () => {
  return (
    <MainDiv>
      <GridLines>
        <GridLinesImage src={gridLines} alt="" />
        <GridLinesMobileImage src={gridLinesMobile} alt="" />
      </GridLines>
      <TitleBox />
      <Image
        style={{ position: "relative", zIndex: 11 }}
        src={bigIcon}
        alt=""
      />
      <GradientRectangle />
    </MainDiv>
  );
};

export default Index;
