import React from "react";
import styled from "styled-components";
import Header from "../components/Layout/Header";
import HeroSection from "../components/HeroSection";
import WelcomeBackSection from "../components/WelcomeSection";
import UnifiedShoppingSection from "../components/UnifiedShoppingSection";
import ControlFinanceSection from "../components/ControlFinanceSection";
import ShopSmartSection from "../components/ShopSmartSection";
import HowItWorkSection from "../components/HowItWorksSection";
import DataControlSection from "../components/DataControlSection";
import ElevateSection from "../components/ElevateSection";
import Footer from "../components/Layout/Footer";

const MainDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow-x: hidden;
`;
const HomeScreen = () => {
  return (
    <MainDiv>
      <Header />
      <HeroSection />
      <WelcomeBackSection />
      <UnifiedShoppingSection />
      <ControlFinanceSection />
      <ShopSmartSection />
      <HowItWorkSection />
      <DataControlSection />
      <ElevateSection />
      <Footer />
    </MainDiv>
  );
};

export default HomeScreen;
