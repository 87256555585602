import React from "react";
import styled from "styled-components";
import { BREAKPOINTS } from "../../theme/breakpoints";

const MainDiv = styled.div`
  display: flex;
  width: 1161px;
  justify-content: center;
  height: 306px;
  align-items: flex-start;
  gap: 23px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    height: auto;
  }
`;
const Card = styled.div`
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding-top: 22px;
  z-index: 11;
  padding-left: 23px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 90%;
    align-self: center;
    padding-bottom: 36px;
  }
`;

const NumberHeader = styled.div`
  width: 100%;
  height: 70.34px;
  border-radius: 49.991px 0 0 49.991px;
  background: rgba(151, 74, 232, 0.1);
  display: flex;
  align-items: center;
  padding-left: 18px;
`;
const NumberRoundBox = styled.div`
  display: flex;
  width: 41.418px;
  height: 41.418px;
  padding: 5.02px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5.02px;
  flex-shrink: 0;
  border-radius: 90.366px;
  background: #974ae8;
  /* Fonts */
  color: var(--Neutral-0, #fff);
  text-align: center;
  font-size: 24.815px;
  font-style: normal;
  font-weight: 500;
  line-height: 31.978px; /* 128.865% */
  letter-spacing: -0.744px;
`;
const TextBox = styled.div`
  width: 100%;
  padding-left: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const CardTitleBox = styled.div`
  margin-top: 30px;
  width: 274.195px;
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 114.286% */
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 20px;
    line-height: 32px; /* 160% */
  }
`;
const InfoText = styled.div`
  padding-right: 20px;
  margin-top: 24px;
  color: #4d4d4d;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 118.75% */
  font-family: "ClashGrotesk-Regular";
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 14px;
    line-height: 19px; /* 135.714% */
  }
`;
const CardBox = () => {
  return (
    <MainDiv>
      <Card>
        <NumberHeader>
          <NumberRoundBox>1</NumberRoundBox>
        </NumberHeader>
        <TextBox>
          <CardTitleBox>Create your ZOOOX ID</CardTitleBox>
          <InfoText>
            Kickstart your smart shopping journey by registering on our mobile
            application and creating your unique ZOOOX ID
          </InfoText>
        </TextBox>
      </Card>
      <Card>
        <NumberHeader>
          <NumberRoundBox>2</NumberRoundBox>
        </NumberHeader>
        <TextBox>
          <CardTitleBox>Shop with your ZOOOX ID </CardTitleBox>
          <InfoText>
            Simply replace your existing email with your ZOOOX email across the
            ecommerce platforms, and we'll automatically organize your purchase
            details in one place
          </InfoText>
        </TextBox>
      </Card>
      <Card>
        <NumberHeader>
          <NumberRoundBox>3</NumberRoundBox>
        </NumberHeader>
        <TextBox>
          <CardTitleBox>Track Orders and Earn Real Cashback</CardTitleBox>
          <InfoText>
            Stay organized, save money, and make every shopping experience
            rewarding
          </InfoText>
        </TextBox>
      </Card>
    </MainDiv>
  );
};

export default CardBox;
