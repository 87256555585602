import React from "react";
import styled from "styled-components";
import TitleBox from "./TitleBox";
import bigIcon from "../../assets/Illustrations/svgs/big-coins-icons.svg";
import gridLines from "../../assets/frame/gridLines.svg";
import { BREAKPOINTS } from "../../theme/breakpoints";
import gridLinesMobile from "../../assets/frame/gridlines-mobile.svg";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 115px;
  position: relative;
  height: 680px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 76px;
    gap: 70px;
    height: 520px;
  }
`;
const GridLines = styled.div`
  position: absolute;
  bottom: 40px;
`;
const BigIconImage = styled.img`
  z-index: 11;
  position: relative;
  top: -90px;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 100%;
  }
`;
const GradientRectangle = styled.div`
  width: 100%;
  height: 293px;
  flex-shrink: 0;
  opacity: 0.1;
  background: linear-gradient(
    0deg,
    #2b0341 -36.81%,
    rgba(205, 148, 255, 0) 100%
  );
  position: absolute;
  bottom: 0;
`;
const GridLinesImage = styled.img`
  display: flex;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;
const GridLinesMobileImage = styled.img`
  display: none;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
  }
`;
const Index = () => {
  return (
    <MainDiv>
      <GridLines>
        <GridLinesImage src={gridLines} alt="" />
        <GridLinesMobileImage src={gridLinesMobile} alt="" />
      </GridLines>
      <TitleBox />
      <BigIconImage src={bigIcon} alt="" />
      <GradientRectangle />
    </MainDiv>
  );
};

export default Index;
