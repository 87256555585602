import React from "react";
import styled from "styled-components";
import TitleBox from "./TitleBox";
import bigInboxIcon from "../../assets/Illustrations/svgs/BigInboxIcon.svg";
import gridLines from "../../assets/frame/gridLines.svg";
import { BREAKPOINTS } from "../../theme/breakpoints";
import gridLinesMobile from "../../assets/frame/gridlines-mobile.svg";

const MainDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 100px;
  gap: 78px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    margin-top: 64.44px;
    gap: 47px;
  }
`;
const GridLines = styled.div`
  position: absolute;
  bottom: 40px;
`;
const GradientRectangle = styled.div`
  width: 100%;
  height: 293px;
  flex-shrink: 0;
  opacity: 0.1;
  background: linear-gradient(
    0deg,
    #2b0341 -36.81%,
    rgba(205, 148, 255, 0) 100%
  );
  position: absolute;
  bottom: 0;
`;
const ImageBox = styled.div`
  display: flex;
  justify-content: center;
`;
const MailText = styled.p`
  color: #fff;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 45.215px; /* 94.198% */
  letter-spacing: -1.44px;
  align-self: stretch;
  opacity: 0.9;
  z-index: 99;
  position: absolute;
  bottom: 284px;
  text-align: center;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    font-size: 27.44px;
    line-height: 25.848px; /* 94.198% */
    letter-spacing: -0.823px;
    bottom: 195px;
  }
  @media (max-width: 437px) {
    bottom: 182px;
  }
  @media (max-width: 420px) {
    bottom: 170px;
  }
  @media (max-width: 400px) {
    bottom: 165px;
  }
  @media (max-width: 380px) {
    bottom: 160px;
  }
  @media (max-width: 360px) {
    bottom: 150px;
  }
`;
const InboxImage = styled.img`
  @media (max-width: ${BREAKPOINTS.xs}px) {
    width: 90%;
  }
`;
const GridLinesImage = styled.img`
  display: flex;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: none;
  }
`;
const GridLinesMobileImage = styled.img`
  display: none;
  @media (max-width: ${BREAKPOINTS.xs}px) {
    display: flex;
  }
`;
const Index = () => {
  return (
    <MainDiv>
      <GridLines>
        <GridLinesImage src={gridLines} alt="" />
        <GridLinesMobileImage src={gridLinesMobile} alt="" />
      </GridLines>
      <TitleBox />
      <ImageBox>
        <MailText>Yours@zooox.xyz</MailText>
        <InboxImage
          style={{ position: "relative", zIndex: 11 }}
          src={bigInboxIcon}
          alt=""
        />
      </ImageBox>
      <GradientRectangle />
    </MainDiv>
  );
};

export default Index;
